import { messaging } from '../firebase';

/**
 * Registers the Firebase Cloud Messaging service worker
 * This is separate from the main service worker
 */
export const registerFCMServiceWorker = async (): Promise<boolean> => {
  try {
    if (!('serviceWorker' in navigator)) {
      console.log('Service workers are not supported in this browser');
      return false;
    }

    // Check if we're in a secure context (HTTPS or localhost)
    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );

    const isSecureContext = window.isSecureContext || isLocalhost;
    if (!isSecureContext) {
      console.warn('Firebase Messaging Service Worker registration skipped: Not in a secure context (HTTPS required)');
      return false;
    }

    // Register the FCM service worker
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      scope: '/',
    });

    console.log('Firebase Messaging Service Worker registered with scope:', registration.scope);
    return true;
  } catch (error) {
    console.error('Error registering Firebase Messaging Service Worker:', error);
    return false;
  }
};

/**
 * Checks if FCM is supported in the current browser
 */
export const isFCMSupported = (): boolean => {
  // Check if we're in a secure context (HTTPS or localhost)
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  const isSecureContext = window.isSecureContext || isLocalhost;

  return 'Notification' in window &&
         'serviceWorker' in navigator &&
         'PushManager' in window &&
         isSecureContext;
};

/**
 * Initializes Firebase Cloud Messaging
 */
export const initializeFCM = async (): Promise<void> => {
  try {
    if (!isFCMSupported()) {
      console.log('Firebase Cloud Messaging is not supported in this browser');
      return;
    }

    // Register the FCM service worker
    await registerFCMServiceWorker();
  } catch (error) {
    console.error('Error initializing Firebase Cloud Messaging:', error);
  }
};
