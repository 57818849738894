import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';
import { userService } from '../services/userService';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signupCompleted: boolean;
  signupLoading: boolean;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  checkSignupStatus: () => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [signupCompleted, setSignupCompleted] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);

  const checkSignupStatus = async (): Promise<boolean> => {
    if (!user) return false;

    setSignupLoading(true);
    try {
      const completed = await userService.hasCompletedSignup(user.uid);
      setSignupCompleted(completed);
      setSignupLoading(false);
      return completed;
    } catch (error) {
      console.error('Error checking signup status:', error);
      setSignupLoading(false);
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);

      if (user) {
        checkSignupStatus();
      } else {
        setSignupCompleted(false);
      }
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const value = {
    user,
    loading,
    signupCompleted,
    signupLoading,
    signInWithGoogle,
    signOut,
    checkSignupStatus,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};