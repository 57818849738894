import React from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import Map from './components/Map';
import ScanArea from './components/ScanArea';
import Profile from './components/Profile';
import Admin from './components/Admin';
import Clues from './components/Clues';
import { AuthProvider } from './contexts/AuthContext';
import Home from './components/Home';
import SignupQuestionnaire from './components/SignupQuestionnaire';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00ff00', // Neon green
    },
    secondary: {
      main: '#0088ff', // Bright blue
    },
    background: {
      default: '#0a0a0a',
      paper: '#1a1a1a',
    },
    text: {
      primary: '#ffffff',
      secondary: '#00ff00',
    },
  },
  typography: {
    fontFamily: "'Share Tech Mono', 'Roboto Mono', monospace",
  },
});

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/scanarea" element={<ScanArea />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/signup" element={<SignupQuestionnaire />} />
            <Route path="/clues" element={<Clues />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
