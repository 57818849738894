import React from 'react';
import { AppBar, Toolbar, Typography, Button, Stack, Box } from '@mui/material';
import { GetApp as GetAppIcon, AccountCircle as AccountCircleIcon, Security as SecurityIcon, Lightbulb as LightbulbIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useInstallPWA } from '../hooks/useInstallPWA';
import { useAuth } from '../contexts/AuthContext';

export const Header: React.FC = () => {
  const { supportsPWA, isIOS, isInStandaloneMode, handleInstallClick } = useInstallPWA();
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <AppBar position="static" sx={{ background: 'linear-gradient(180deg, #1a1a1a 0%, #0a0a0a 100%)' }}>
      <Toolbar>
        <SecurityIcon sx={{ mr: 1, color: '#00ff00' }} />
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            cursor: 'pointer',
            fontFamily: "'Share Tech Mono', monospace",
            letterSpacing: '0.1em',
            textShadow: '0 0 10px #00ff00',
          }}
          onClick={() => navigate('/')}
        >
          OPERATION_STASH
        </Typography>
        <Stack direction="row" spacing={1}>
          {supportsPWA && !isInStandaloneMode && (
            <Button
              color="inherit"
              startIcon={<GetAppIcon />}
              onClick={handleInstallClick}
              sx={{
                borderColor: '#00ff00',
                '&:hover': {
                  backgroundColor: 'rgba(0, 255, 0, 0.1)',
                }
              }}
            >
              {isIOS ? 'INSTALL_GUIDE' : 'DEPLOY_LOCAL'}
            </Button>
          )}
          {user && (
            <Button
              color="inherit"
              startIcon={<LightbulbIcon />}
              onClick={() => navigate('/clues')}
              sx={{
                borderColor: '#00ff00',
                '&:hover': {
                  backgroundColor: 'rgba(0, 255, 0, 0.1)',
                }
              }}
            >
              INTEL
            </Button>
          )}
          <Button
            color="inherit"
            startIcon={<AccountCircleIcon />}
            onClick={() => navigate('/profile')}
            sx={{
              borderColor: '#00ff00',
              '&:hover': {
                backgroundColor: 'rgba(0, 255, 0, 0.1)',
              }
            }}
          >
            {user ? 'AGENT_PROFILE' : 'AUTHENTICATE'}
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};