import { useState, useEffect } from 'react';
import { messagingService } from '../services/messagingService';
import { useAuth } from '../contexts/AuthContext';

interface NotificationState {
  permissionStatus: NotificationPermission;
  supported: boolean;
  isIOS: boolean;
  error: string | null;
  fcmSupported: boolean;
}

export const useNotifications = () => {
  const { user } = useAuth();
  const [state, setState] = useState<NotificationState>({
    permissionStatus: 'default',
    supported: 'Notification' in window,
    isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream,
    error: null,
    fcmSupported: 'serviceWorker' in navigator,
  });

  useEffect(() => {
    if (state.supported) {
      setState(prev => ({
        ...prev,
        permissionStatus: Notification.permission,
      }));
    }
  }, [state.supported]);

  const requestPermission = async () => {
    // Special handling for iOS
    if (state.isIOS) {
      // On iOS, we need to guide the user to enable notifications in settings
      alert('To enable notifications on iOS:\n\n1. Open Settings\n2. Go to Safari (or your browser)\n3. Scroll down to Notifications\n4. Enable notifications for this site');

      // We can't programmatically check if they've enabled it, so we'll assume they did
      setState(prev => ({
        ...prev,
        permissionStatus: 'granted', // This is optimistic - we can't actually check
        error: null,
      }));
      return true;
    }

    // Standard flow for other browsers
    if (!state.supported) {
      setState(prev => ({
        ...prev,
        error: 'Notifications are not supported in this browser',
      }));
      return false;
    }

    try {
      // First request standard notification permission
      const permission = await Notification.requestPermission();

      if (permission !== 'granted') {
        setState(prev => ({
          ...prev,
          permissionStatus: permission,
          error: null,
        }));
        return false;
      }

      // If we have FCM support and a user is logged in, register for FCM
      let fcmSuccess = false;
      if (state.fcmSupported && user) {
        fcmSuccess = await messagingService.requestPermission(user.uid);
      }

      setState(prev => ({
        ...prev,
        permissionStatus: permission,
        error: null,
      }));

      return permission === 'granted';
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Unknown error',
      }));
      return false;
    }
  };

  const sendTestNotification = () => {
    // On iOS, we can't actually send a test notification
    if (state.isIOS) {
      return true; // Just pretend it worked
    }

    if (state.permissionStatus === 'granted') {
      try {
        // Use the messaging service to show a notification
        messagingService.showNotification({
          title: 'OPERATION_STASH',
          body: 'Mission update received. You are now connected to the network.',
          icon: '/logo192.png',
        });
        return true;
      } catch (error) {
        setState(prev => ({
          ...prev,
          error: error instanceof Error ? error.message : 'Unknown error',
        }));
        return false;
      }
    }
    return false;
  };

  return {
    ...state,
    requestPermission,
    sendTestNotification,
  };
};
