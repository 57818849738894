import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeFCM } from './utils/registerFCM';
import { testFCM } from './utils/testFCM';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Register service worker for PWA functionality
serviceWorkerRegistration.register();

// Initialize Firebase Cloud Messaging
initializeFCM().catch(error => {
  console.error('Failed to initialize FCM:', error);
});

// Make FCM test function available globally
(window as any).testFCM = testFCM;
console.log('FCM test utility available. Run testFCM() in the console to test FCM setup.');
