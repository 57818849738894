import { getToken, onMessage } from 'firebase/messaging';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, messaging } from '../firebase';
import { registerFCMServiceWorker, isFCMSupported } from '../utils/registerFCM';

// Your web app's Firebase configuration
const vapidKey = 'BIFo8Vr99skhDkZxJm5A_CTxoHZX6ytbEYmjjshhyPDtGxOtcEO3l9cAHBAl3USx1cMqCax03AG24h-HwD0YMWA'; // Replace with your actual VAPID key from Firebase Console

export interface NotificationPayload {
  title: string;
  body: string;
  icon?: string;
  clickAction?: string;
  data?: Record<string, string>;
}

export const messagingService = {
  async requestPermission(userId: string): Promise<boolean> {
    try {
      if (!isFCMSupported()) {
        console.log('This browser does not support notifications, FCM, or is not in a secure context');

        // If we're not in a secure context but notifications are supported, we can still use basic notifications
        if ('Notification' in window) {
          const permission = await Notification.requestPermission();
          return permission === 'granted';
        }

        return false;
      }

      // First, register the FCM service worker
      const swRegistered = await registerFCMServiceWorker();
      if (!swRegistered) {
        console.log('Failed to register FCM service worker');

        // Fall back to basic notifications if service worker registration fails
        if ('Notification' in window) {
          const permission = await Notification.requestPermission();
          return permission === 'granted';
        }

        return false;
      }

      // Request notification permission
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.log('Notification permission not granted');
        return false;
      }

      try {
        // Get FCM token
        console.log('Getting FCM token...');
        const token = await getToken(messaging, { vapidKey });

        if (!token) {
          console.log('No registration token available');
          return permission === 'granted'; // Still return true if notifications are allowed
        }

        console.log('FCM token obtained:', token);

        // Save the token to Firestore
        await this.saveToken(userId, token);

        // Set up foreground message handler
        this.setupForegroundHandler();
      } catch (tokenError) {
        console.error('Error getting FCM token:', tokenError);
        // Still return true if notifications are allowed
        return permission === 'granted';
      }

      return true;
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      return false;
    }
  },

  async saveToken(userId: string, token: string): Promise<void> {
    try {
      const userTokensRef = doc(db, 'userTokens', userId);
      const userTokensDoc = await getDoc(userTokensRef);

      if (userTokensDoc.exists()) {
        const tokens = userTokensDoc.data().tokens || [];
        if (!tokens.includes(token)) {
          await setDoc(userTokensRef, {
            tokens: [...tokens, token],
            lastUpdated: new Date()
          }, { merge: true });
        }
      } else {
        await setDoc(userTokensRef, {
          tokens: [token],
          lastUpdated: new Date()
        });
      }
    } catch (error) {
      console.error('Error saving FCM token:', error);
      throw error;
    }
  },

  setupForegroundHandler(): void {
    try {
      // Check if we're in a secure context before setting up the handler
      const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
          window.location.hostname === '[::1]' ||
          window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
      );

      const isSecureContext = window.isSecureContext || isLocalhost;
      if (!isSecureContext) {
        console.warn('Foreground message handler setup skipped: Not in a secure context');
        return;
      }

      onMessage(messaging, (payload) => {
        console.log('Message received in foreground:', payload);

        // Display a notification if the app is in the foreground
        if (payload.notification) {
          const { title, body } = payload.notification;
          new Notification(title as string, {
            body: body as string,
            icon: '/logo192.png'
          });
        }
      });
    } catch (error) {
      console.error('Error setting up foreground handler:', error);
    }
  },

  // This is a helper method to show a notification using the Notification API
  // Useful for testing or showing notifications from within the app
  showNotification(notification: NotificationPayload): boolean {
    try {
      if (Notification.permission === 'granted') {
        new Notification(notification.title, {
          body: notification.body,
          icon: notification.icon || '/logo192.png',
          data: notification.data
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error showing notification:', error);
      return false;
    }
  }
};
