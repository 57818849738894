import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, GeoJSON, Circle } from 'react-leaflet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import northeastStates from '../data/northeast-states.json';
import { db } from '../firebase';

// Fix Leaflet icon issue
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Add custom CSS to hide attribution
const hideAttribution = `
  .leaflet-control-attribution {
    display: none;
  }
`;

interface CircleData {
  center: [number, number];
  radius: number;
  active: boolean;
}

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

// MapCenter component to get current center
const MapCenter: React.FC<{ onScanClick: (center: L.LatLng) => void }> = ({ onScanClick }) => {
  const map = useMap();
  
  return (
    <Button
      variant="contained"
      sx={{
        position: 'absolute',
        bottom: 16,
        right: 16,
        zIndex: 1000,
        backgroundColor: '#00ff00',
        color: '#000',
        fontFamily: "'Share Tech Mono', monospace",
        '&:hover': {
          backgroundColor: '#00cc00',
        },
      }}
      onClick={() => onScanClick(map.getCenter())}
    >
      USE_SCANTOOL
    </Button>
  );
};

const Map: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    // Add custom style to hide attribution
    const style = document.createElement('style');
    style.textContent = hideAttribution;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  const lat = parseFloat(searchParams.get('lat') || '25.175');
  const lng = parseFloat(searchParams.get('lng') || '93.0155');
  const zoom = parseInt(searchParams.get('zoom') || '7', 10);
  const position: [number, number] = [lat, lng];
  
  const [circleData, setCircleData] = useState<CircleData | null>(null);
  const [mapStyle, setMapStyle] = useState<'street' | 'satellite'>('street');
  
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'game', 'current'), (doc) => {
      if (doc.exists() && doc.data().active) {
        const data = doc.data();
        setCircleData({
          center: data.circle.center,
          radius: data.circle.radius,
          active: data.active
        });
      } else {
        setCircleData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleScanClick = (center: L.LatLng) => {
    navigate(`/scanarea?lat=${center.lat}&lng=${center.lng}`);
  };

  const geoJSONStyle = {
    color: '#00ff00', // Changed to neon green
    weight: 2,
    opacity: 0.8,
    fillOpacity: 0,
  };

  const mapSize = 'min(calc(100vh - 64px), 100vw)';
  
  return (
    <Box 
      sx={{ 
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        p: 2
      }}
    >
      <Box 
        sx={{ 
          width: mapSize,
          height: mapSize,
          position: 'relative',
          border: '2px solid #00ff00',
          borderRadius: 2,
          overflow: 'hidden',
          boxShadow: '0 0 20px rgba(0, 255, 0, 0.2)',
        }}
      >
        <MapContainer 
          center={position} 
          zoom={zoom} 
          style={{ height: '100%', width: '100%' }}
          attributionControl={false}
        >
          <Box sx={{ 
            position: 'absolute', 
            top: 10, 
            right: 10, 
            zIndex: 1000 
          }}>
            <ToggleButtonGroup
              value={mapStyle}
              exclusive
              size="small"
              onChange={(_, newStyle) => newStyle && setMapStyle(newStyle)}
              sx={{ 
                backgroundColor: '#1a1a1a',
                border: '1px solid #00ff00',
                '& .MuiToggleButton-root': {
                  color: '#00ff00',
                  padding: '4px 8px',
                  fontSize: '0.875rem',
                  fontFamily: "'Share Tech Mono', monospace",
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(0, 255, 0, 0.2)',
                    color: '#00ff00',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 255, 0, 0.1)',
                  },
                }
              }}
            >
              <ToggleButton value="street">TERRAIN</ToggleButton>
              <ToggleButton value="satellite">SATELLITE</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {mapStyle === 'street' && (
            <>
              <TileLayer
                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                attribution=""
              />
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                attribution=""
              />
            </>
          )}
          {mapStyle === 'satellite' && (
            <>
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution=""
              />
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                attribution=""
              />
            </>
          )}
          <GeoJSON data={northeastStates as any} style={geoJSONStyle} />
          {circleData && (
            <Circle
              center={circleData.center}
              radius={circleData.radius}
              pathOptions={{ color: '#ff0000', weight: 2 }}
              fillOpacity={0}
            />
          )}
          <MapCenter onScanClick={handleScanClick} />
        </MapContainer>
      </Box>
    </Box>
  );
};

export default Map; 