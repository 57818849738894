import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export interface UserProfile {
  uid: string;
  displayName: string;
  email?: string;
  phoneNumber?: string;
  instagramId?: string;
  location?: {
    latitude: number;
    longitude: number;
  };
  signupCompleted?: boolean;
  notificationsEnabled?: boolean;
  lastScanTime?: Date;
}

export const userService = {
  async getUserProfile(uid: string): Promise<UserProfile | null> {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        return userDoc.data() as UserProfile;
      }
      return null;
    } catch (error) {
      console.error('Error getting user profile:', error);
      return null;
    }
  },

  async updateUserProfile(profile: Partial<UserProfile> & { uid: string }): Promise<boolean> {
    try {
      await setDoc(doc(db, 'users', profile.uid), profile, { merge: true });
      return true;
    } catch (error) {
      console.error('Error updating user profile:', error);
      return false;
    }
  },

  async createUserProfile(profile: UserProfile): Promise<boolean> {
    try {
      await setDoc(doc(db, 'users', profile.uid), profile);
      return true;
    } catch (error) {
      console.error('Error creating user profile:', error);
      return false;
    }
  },

  async markSignupComplete(uid: string): Promise<boolean> {
    return this.updateUserProfile({
      uid,
      signupCompleted: true,
    });
  },

  async hasCompletedSignup(uid: string): Promise<boolean> {
    const profile = await this.getUserProfile(uid);
    return !!profile?.signupCompleted;
  }
};
