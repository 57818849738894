import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, Stack, Alert } from '@mui/material';
import { Google as GoogleIcon, Security as SecurityIcon, Terminal as TerminalIcon, AppRegistration as AppRegistrationIcon } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
// import { useInstallPWA } from '../hooks/useInstallPWA';
import { useNavigate } from 'react-router-dom';

const Profile: React.FC = () => {
  const { user, signInWithGoogle, signOut, signupCompleted, checkSignupStatus } = useAuth();
  // const { supportsPWA, handleInstallClick } = useInstallPWA();
  const navigate = useNavigate();
  const [hasCompletedSignup, setHasCompletedSignup] = useState<boolean | null>(null);

  useEffect(() => {
    if (user) {
      const checkSignup = async () => {
        const completed = await checkSignupStatus();
        setHasCompletedSignup(completed);
      };
      checkSignup();
    } else {
      setHasCompletedSignup(null);
    }
  }, [user, checkSignupStatus]);

  return (
    <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          background: 'linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%)',
          border: '1px solid #00ff00',
          boxShadow: '0 0 20px rgba(0, 255, 0, 0.2)',
        }}
      >
        <Stack spacing={3}>
          {user ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <SecurityIcon sx={{ color: '#00ff00', fontSize: 40 }} />
                <Typography
                  variant="h5"
                  component="h1"
                  gutterBottom
                  sx={{
                    color: '#00ff00',
                    fontFamily: "'Share Tech Mono', monospace",
                    letterSpacing: '0.1em',
                    textShadow: '0 0 10px #00ff00',
                  }}
                >
                  AGENT_{user.displayName?.toUpperCase().replace(/\s+/g, '_')}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: '#00ff00',
                  opacity: 0.8,
                  fontFamily: "'Share Tech Mono', monospace",
                }}
              >
                AUTHENTICATION_STATUS: ACTIVE
              </Typography>
              <Box sx={{
                p: 2,
                border: '1px solid #00ff00',
                borderRadius: 1,
                backgroundColor: 'rgba(0, 255, 0, 0.1)',
              }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    color: '#fff',
                    fontFamily: "'Share Tech Mono', monospace",
                  }}
                >
                  AGENT_ID: {user.email}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    color: '#fff',
                    fontFamily: "'Share Tech Mono', monospace",
                  }}
                >
                  CLEARANCE_LEVEL: ALPHA
                </Typography>
              </Box>
              {!hasCompletedSignup && (
                <Alert
                  severity="warning"
                  sx={{
                    mb: 2,
                    border: '1px solid #ffcc00',
                    '& .MuiAlert-icon': {
                      color: '#ffcc00'
                    }
                  }}
                >
                  AGENT_PROFILE_INCOMPLETE: Complete signup to access all mission features
                </Alert>
              )}

              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#00ff00',
                  color: '#000',
                  '&:hover': {
                    backgroundColor: '#00cc00',
                  },
                }}
                onClick={() => navigate('/')}
                fullWidth
              >
                INITIATE_MISSION
              </Button>

              {!hasCompletedSignup && (
                <Button
                  variant="outlined"
                  startIcon={<AppRegistrationIcon />}
                  sx={{
                    mt: 2,
                    color: '#ffcc00',
                    borderColor: '#ffcc00',
                    '&:hover': {
                      borderColor: '#ffcc00',
                      backgroundColor: 'rgba(255, 204, 0, 0.1)',
                    }
                  }}
                  onClick={() => navigate('/signup')}
                  fullWidth
                >
                  COMPLETE_AGENT_REGISTRATION
                </Button>
              )}
              <Button
                variant="outlined"
                sx={{
                  color: '#00ff00',
                  borderColor: '#00ff00',
                  '&:hover': {
                    borderColor: '#00ff00',
                    backgroundColor: 'rgba(0, 255, 0, 0.1)',
                  }
                }}
                onClick={signOut}
              >
                TERMINATE_SESSION
              </Button>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <TerminalIcon sx={{ color: '#00ff00', fontSize: 40 }} />
                <Typography
                  variant="h5"
                  component="h1"
                  gutterBottom
                  sx={{
                    color: '#00ff00',
                    fontFamily: "'Share Tech Mono', monospace",
                    letterSpacing: '0.1em',
                    textShadow: '0 0 10px #00ff00',
                  }}
                >
                  AUTHENTICATION_REQUIRED
                </Typography>
              </Box>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  color: '#fff',
                  fontFamily: "'Share Tech Mono', monospace",
                }}
              >
                SECURE_ACCESS_NEEDED_FOR_MISSION_DEPLOYMENT
              </Typography>
              <Button
                variant="contained"
                startIcon={<GoogleIcon />}
                onClick={signInWithGoogle}
                fullWidth
                sx={{
                  backgroundColor: '#00ff00',
                  color: '#000',
                  '&:hover': {
                    backgroundColor: '#00cc00',
                  },
                }}
              >
                AUTHENTICATE_WITH_GOOGLE
              </Button>
            </>
          )}

          {/* {supportsPWA && (
            <Button
              variant="outlined"
              sx={{
                color: '#00ff00',
                borderColor: '#00ff00',
                '&:hover': {
                  borderColor: '#00ff00',
                  backgroundColor: 'rgba(0, 255, 0, 0.1)',
                }
              }}
              onClick={handleInstallClick}
              fullWidth
            >
              DEPLOY_LOCAL_INSTANCE
            </Button>
          )} */}
        </Stack>
      </Paper>
    </Box>
  );
};

export default Profile;