import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  CircularProgress,
  Alert,
  Stack
} from '@mui/material';
import {
  GetApp as GetAppIcon,
  Notifications as NotificationsIcon,
  LocationOn as LocationOnIcon,
  Person as PersonIcon,
  Instagram as InstagramIcon,
  Phone as PhoneIcon,
  Security as SecurityIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useInstallPWA } from '../hooks/useInstallPWA';
import { useGeolocation } from '../hooks/useGeolocation';
import { useNotifications } from '../hooks/useNotifications';
import { userService, UserProfile } from '../services/userService';

const steps = [
  'DEPLOY_LOCAL_INSTANCE',
  'AGENT_IDENTIFICATION',
  'MISSION_PERMISSIONS',
  'MISSION_BRIEFING'
];

const SignupQuestionnaire: React.FC = () => {
  const { user, checkSignupStatus } = useAuth();
  const { supportsPWA, isIOS, isInStandaloneMode, handleInstallClick } = useInstallPWA();
  const {
    position,
    error: geoError,
    loading: geoLoading,
    requestGeolocation
  } = useGeolocation();
  const {
    permissionStatus: notificationPermissionStatus,
    supported: notificationsSupported,
    isIOS: isIOSNotifications,
    error: notificationError,
    requestPermission: requestNotificationPermission
  } = useNotifications();

  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: user?.displayName || '',
    phoneNumber: '',
    instagramId: '',
  });

  useEffect(() => {
    if (!user) {
      navigate('/profile');
    }
  }, [user, navigate]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (!user || !position) return;

    setLoading(true);
    setError(null);

    try {
      const userProfile: UserProfile = {
        uid: user.uid,
        displayName: formData.name || user.displayName || 'Unknown Agent',
        email: user.email || undefined,
        phoneNumber: formData.phoneNumber,
        instagramId: formData.instagramId,
        location: {
          latitude: position.latitude,
          longitude: position.longitude,
        },
        signupCompleted: true,
        notificationsEnabled: notificationPermissionStatus === 'granted',
      };

      const success = await userService.createUserProfile(userProfile);

      if (success) {
        await checkSignupStatus();
        navigate('/');
      } else {
        setError('Failed to save profile data. Please try again.');
      }
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
      console.error('Error saving user profile:', err);
    } finally {
      setLoading(false);
    }
  };

  const isStepComplete = (step: number): boolean => {
    switch (step) {
      case 0: // PWA Installation
        // Allow proceeding even if PWA installation is available but not completed
        return activeStep > 0 || isInStandaloneMode || true;
      case 1: // Personal Info
        return !!formData.name && !!formData.phoneNumber && !!formData.instagramId;
      case 2: // Permissions
        return !!position && notificationPermissionStatus === 'granted';
      default:
        return false;
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0: // PWA Installation
        return (
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: '#00ff00',
                fontFamily: "'Share Tech Mono', monospace",
              }}
            >
              {steps[0]}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#fff',
                fontFamily: "'Share Tech Mono', monospace",
                mb: 2
              }}
            >
              For optimal mission performance and security, deploy a local instance of OPERATION_STASH on your device.
            </Typography>

            {isInStandaloneMode ? (
              <Alert severity="success" sx={{ mt: 2 }}>
                LOCAL DEPLOYMENT CONFIRMED. APP IS INSTALLED.
              </Alert>
            ) : supportsPWA ? (
              <>
                <Button
                  variant="contained"
                  startIcon={<GetAppIcon />}
                  onClick={handleInstallClick}
                  fullWidth
                  sx={{
                    mt: 2,
                    backgroundColor: '#00ff00',
                    color: '#000',
                    '&:hover': {
                      backgroundColor: '#00cc00',
                    }
                  }}
                >
                  {isIOS ? 'VIEW INSTALLATION INSTRUCTIONS' : 'DEPLOY_LOCAL_INSTANCE'}
                </Button>
                {isIOS && (
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'block',
                      mt: 1,
                      color: '#aaa',
                      textAlign: 'center'
                    }}
                  >
                    On iOS: Tap Share → Add to Home Screen → Add
                  </Typography>
                )}
              </>
            ) : (
              <Alert severity="info" sx={{ mt: 2 }}>
                PWA INSTALLATION NOT SUPPORTED ON THIS BROWSER. YOU MAY PROCEED.
              </Alert>
            )}
          </Box>
        );

      case 1: // Personal Info
        return (
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: '#00ff00',
                fontFamily: "'Share Tech Mono', monospace",
              }}
            >
              {steps[1]}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                color: '#fff',
                fontFamily: "'Share Tech Mono', monospace",
              }}
            >
              Provide secure identification details for mission communications.
            </Typography>

            <Stack spacing={3} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="AGENT_NAME"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                InputProps={{
                  startAdornment: <PersonIcon sx={{ mr: 1, color: '#00ff00' }} />,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#00ff00',
                    },
                    '&:hover fieldset': {
                      borderColor: '#00ff00',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00ff00',
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="SECURE_COMMS_CHANNEL (PHONE)"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
                helperText="Required for mission-critical communications if you win"
                InputProps={{
                  startAdornment: <PhoneIcon sx={{ mr: 1, color: '#00ff00' }} />,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#00ff00',
                    },
                    '&:hover fieldset': {
                      borderColor: '#00ff00',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00ff00',
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="SOCIAL_NETWORK_ID (INSTAGRAM)"
                name="instagramId"
                value={formData.instagramId}
                onChange={handleInputChange}
                required
                helperText="For tagging you in mission success announcements"
                InputProps={{
                  startAdornment: <InstagramIcon sx={{ mr: 1, color: '#00ff00' }} />,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#00ff00',
                    },
                    '&:hover fieldset': {
                      borderColor: '#00ff00',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#00ff00',
                    },
                  },
                }}
              />
            </Stack>
          </Box>
        );

      case 2: // Permissions
        return (
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: '#00ff00',
                fontFamily: "'Share Tech Mono', monospace",
              }}
            >
              {steps[2]}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                color: '#fff',
                fontFamily: "'Share Tech Mono', monospace",
              }}
            >
              Grant necessary permissions for optimal mission performance.
            </Typography>

            <Stack spacing={3} sx={{ mt: 2 }}>
              <Paper
                elevation={2}
                sx={{
                  p: 2,
                  border: '1px solid #00ff00',
                  backgroundColor: 'rgba(0, 255, 0, 0.1)',
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    color: '#00ff00',
                    fontFamily: "'Share Tech Mono', monospace",
                  }}
                >
                  GEOLOCATION_ACCESS
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  sx={{
                    color: '#fff',
                    fontFamily: "'Share Tech Mono', monospace",
                  }}
                >
                  Required for mission deployment in your sector.
                </Typography>

                {position ? (
                  <Alert severity="success" sx={{ mb: 2 }}>
                    LOCATION_ACQUIRED: {position.latitude.toFixed(6)}, {position.longitude.toFixed(6)}
                  </Alert>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      startIcon={<LocationOnIcon />}
                      onClick={requestGeolocation}
                      disabled={geoLoading}
                      fullWidth
                      sx={{
                        backgroundColor: '#00ff00',
                        color: '#000',
                        '&:hover': {
                          backgroundColor: '#00cc00',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#333',
                          color: '#666',
                        }
                      }}
                    >
                      {geoLoading ? 'ACQUIRING_LOCATION...' : 'ENABLE_LOCATION_TRACKING'}
                    </Button>
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'block',
                        mt: 1,
                        color: '#aaa',
                        textAlign: 'center'
                      }}
                    >
                      Tap to allow location access when prompted
                    </Typography>
                  </>
                )}

                {geoError && (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    LOCATION_ERROR: {geoError}
                  </Alert>
                )}
              </Paper>

              <Paper
                elevation={2}
                sx={{
                  p: 2,
                  border: '1px solid #00ff00',
                  backgroundColor: 'rgba(0, 255, 0, 0.1)',
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    color: '#00ff00',
                    fontFamily: "'Share Tech Mono', monospace",
                  }}
                >
                  MISSION_ALERTS
                </Typography>
                <Typography
                  variant="body2"
                  paragraph
                  sx={{
                    color: '#fff',
                    fontFamily: "'Share Tech Mono', monospace",
                  }}
                >
                  Required for critical mission updates and alerts.
                </Typography>

                {notificationPermissionStatus === 'granted' ? (
                  <Alert severity="success" sx={{ mb: 2 }}>
                    ALERTS_ENABLED
                  </Alert>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      startIcon={<NotificationsIcon />}
                      onClick={requestNotificationPermission}
                      disabled={!notificationsSupported}
                      fullWidth
                      sx={{
                        backgroundColor: '#00ff00',
                        color: '#000',
                        '&:hover': {
                          backgroundColor: '#00cc00',
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#333',
                          color: '#666',
                        }
                      }}
                    >
                      ENABLE_MISSION_ALERTS
                    </Button>
                    {isIOSNotifications && (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          mt: 1,
                          color: '#aaa',
                          textAlign: 'center'
                        }}
                      >
                        On iOS: Follow the instructions to enable notifications in Settings
                      </Typography>
                    )}
                  </>
                )}

                {notificationError && (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    NOTIFICATION_ERROR: {notificationError}
                  </Alert>
                )}
              </Paper>
            </Stack>
          </Box>
        );

      case 3: // Final step
        return (
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: '#00ff00',
                fontFamily: "'Share Tech Mono', monospace",
              }}
            >
              {steps[3]}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{
                color: '#fff',
                fontFamily: "'Share Tech Mono', monospace",
              }}
            >
              Agent {formData.name}, you are now ready to begin your mission. Your secure profile has been established and all necessary permissions have been granted.
            </Typography>

            <Box sx={{
              p: 2,
              border: '1px solid #00ff00',
              borderRadius: 1,
              backgroundColor: 'rgba(0, 255, 0, 0.1)',
              mt: 2,
              mb: 3,
            }}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  color: '#fff',
                  fontFamily: "'Share Tech Mono', monospace",
                }}
              >
                AGENT_ID: {user?.email}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  color: '#fff',
                  fontFamily: "'Share Tech Mono', monospace",
                }}
              >
                SECURE_COMMS: {formData.phoneNumber}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  color: '#fff',
                  fontFamily: "'Share Tech Mono', monospace",
                }}
              >
                SOCIAL_NETWORK: {formData.instagramId}
              </Typography>
              {position && (
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    color: '#fff',
                    fontFamily: "'Share Tech Mono', monospace",
                  }}
                >
                  DEPLOYMENT_ZONE: {position.latitude.toFixed(6)}, {position.longitude.toFixed(6)}
                </Typography>
              )}
            </Box>

            <Button
              variant="contained"
              startIcon={<SecurityIcon />}
              onClick={handleSubmit}
              disabled={loading}
              fullWidth
              sx={{
                backgroundColor: '#00ff00',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#00cc00',
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'INITIATE_MISSION'
              )}
            </Button>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          background: 'linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%)',
          border: '1px solid #00ff00',
          boxShadow: '0 0 20px rgba(0, 255, 0, 0.2)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <SecurityIcon sx={{ color: '#00ff00', fontSize: 40 }} />
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{
              color: '#00ff00',
              fontFamily: "'Share Tech Mono', monospace",
              letterSpacing: '0.1em',
              textShadow: '0 0 10px #00ff00',
              mb: 0,
            }}
          >
            AGENT_ONBOARDING
          </Typography>
        </Box>


        {renderStepContent(activeStep)}

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 4 }}>
          <Button
            variant="outlined"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              mr: 1,
              color: '#00ff00',
              borderColor: '#00ff00',
              '&:hover': {
                borderColor: '#00ff00',
                backgroundColor: 'rgba(0, 255, 0, 0.1)',
              }
            }}
          >
            BACK
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />

          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={!isStepComplete(activeStep)}
              sx={{
                backgroundColor: '#00ff00',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#00cc00',
                },
              }}
            >
              NEXT
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default SignupQuestionnaire;
