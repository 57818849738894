import { useState, useEffect } from 'react';

interface GeolocationState {
  position: {
    latitude: number;
    longitude: number;
  } | null;
  error: string | null;
  loading: boolean;
  permissionStatus: PermissionState | null;
}

export const useGeolocation = () => {
  const [state, setState] = useState<GeolocationState>({
    position: null,
    error: null,
    loading: false, // Changed from true to false so button isn't initially disabled
    permissionStatus: null,
  });

  // Check if geolocation is available in the browser
  const isGeolocationAvailable = 'geolocation' in navigator;

  // Function to request geolocation permission and get position
  const requestGeolocation = async () => {
    if (!isGeolocationAvailable) {
      setState(prev => ({
        ...prev,
        error: 'Geolocation is not supported by your browser',
        loading: false
      }));
      return;
    }

    setState(prev => ({ ...prev, loading: true, error: null }));

    try {
      // Check if the Permissions API is available (not available on all browsers/devices)
      if ('permissions' in navigator) {
        try {
          const permissionStatus = await navigator.permissions.query({ name: 'geolocation' as PermissionName });

          setState(prev => ({
            ...prev,
            permissionStatus: permissionStatus.state
          }));

          // Listen for permission changes
          permissionStatus.addEventListener('change', () => {
            setState(prev => ({
              ...prev,
              permissionStatus: permissionStatus.state
            }));
          });
        } catch (permError) {
          console.warn('Permissions API error:', permError);
          // Continue even if permissions API fails
        }
      }

      // Request the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setState(prev => ({
            ...prev,
            position: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            error: null,
            loading: false,
          }));
        },
        (error) => {
          console.error('Geolocation error:', error);
          let errorMessage = error.message;

          // Provide more user-friendly error messages
          if (error.code === 1) { // PERMISSION_DENIED
            errorMessage = 'Location permission denied. Please enable location access in your device settings.';
          } else if (error.code === 2) { // POSITION_UNAVAILABLE
            errorMessage = 'Location information is unavailable. Please try again.';
          } else if (error.code === 3) { // TIMEOUT
            errorMessage = 'Location request timed out. Please try again.';
          }

          setState(prev => ({
            ...prev,
            position: null,
            error: errorMessage,
            loading: false,
          }));
        },
        {
          enableHighAccuracy: true,
          timeout: 20000, // Increased timeout for slower devices
          maximumAge: 0 // Always get fresh position
        }
      );
    } catch (error) {
      setState(prev => ({
        ...prev,
        position: null,
        error: error instanceof Error ? error.message : 'Unknown error occurred while accessing location',
        loading: false,
      }));
    }
  };

  // Cleanup function for any listeners
  useEffect(() => {
    return () => {
      // Any cleanup needed
    };
  }, []);

  return {
    ...state,
    isAvailable: isGeolocationAvailable,
    requestGeolocation,
  };
};
