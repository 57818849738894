import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Stack, TextField, Switch, FormControlLabel, Divider, IconButton, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { MapContainer, TileLayer, Circle, Marker, useMap, useMapEvents } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAdmin } from '../hooks/useAdmin';
import { Clue, clueService } from '../services/clueService';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Lightbulb as LightbulbIcon } from '@mui/icons-material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface GameData {
  stashLocation: [number, number];
  circle: {
    center: [number, number];
    radius: number;
  };
  active: boolean;
  winnerUid?: string;
  winTime?: Date;
}

interface ClueDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (clue: { text: string; active: boolean }) => void;
  initialClue?: { id: string; text: string; active: boolean };
  title: string;
}

const ClueDialog: React.FC<ClueDialogProps> = ({ open, onClose, onSave, initialClue, title }) => {
  const [clueText, setClueText] = useState(initialClue?.text || '');
  const [active, setActive] = useState(initialClue?.active !== false);

  useEffect(() => {
    if (open) {
      setClueText(initialClue?.text || '');
      setActive(initialClue?.active !== false);
    }
  }, [open, initialClue]);

  const handleSave = () => {
    if (!clueText.trim()) return;

    onSave({
      text: clueText.trim(),
      active
    });

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <TextField
            label="Clue Text"
            multiline
            rows={4}
            value={clueText}
            onChange={(e) => setClueText(e.target.value)}
            fullWidth
            placeholder="Enter clue text here..."
          />
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
            }
            label="Active"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!clueText.trim()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Component to check if stash is within circle
const CircleCheck: React.FC<{
  stashLocation: [number, number];
  circleCenter: [number, number];
  radius: number;
  onValidityChange: (isValid: boolean) => void;
}> = ({ stashLocation, circleCenter, radius, onValidityChange }) => {
  const map = useMap();

  useEffect(() => {
    const circle = L.circle(circleCenter, { radius });
    const isWithinCircle = map.distance(stashLocation, circleCenter) <= radius;
    onValidityChange(isWithinCircle);
  }, [stashLocation, circleCenter, radius, map, onValidityChange]);

  return null;
};

// Component to handle map clicks and show proposed circle
const MapClickHandler: React.FC<{
  onCircleProposed: (center: [number, number]) => void;
  proposedCenter: [number, number] | null;
  proposedRadius: number;
}> = ({ onCircleProposed, proposedCenter, proposedRadius }) => {
  useMapEvents({
    click: (e) => {
      onCircleProposed([e.latlng.lat, e.latlng.lng]);
    },
  });

  return proposedCenter ? (
    <Circle
      center={proposedCenter}
      radius={proposedRadius}
      pathOptions={{ color: 'orange', fillOpacity: 0.1 }}
    />
  ) : null;
};

const Admin: React.FC = () => {
  const { isAdmin, loading } = useAdmin();
  const navigate = useNavigate();
  const [gameData, setGameData] = useState<GameData>({
    stashLocation: [25.55, 92.99],
    circle: {
      center: [25.55, 92.99],
      radius: 1000,
    },
    active: false,
  });
  const [proposedCenter, setProposedCenter] = useState<[number, number] | null>(null);
  const [proposedRadius, setProposedRadius] = useState<number>(1000);
  const [isValid, setIsValid] = useState(false);
  const [winnerInfo, setWinnerInfo] = useState<{
    displayName?: string;
    email?: string;
  } | null>(null);

  // Clues state
  const [clues, setClues] = useState<Clue[]>([]);
  const [clueDialogOpen, setClueDialogOpen] = useState(false);
  const [editingClue, setEditingClue] = useState<{ id: string; text: string; active: boolean } | undefined>(undefined);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const loadGameData = async () => {
      try {
        const gameDoc = await getDoc(doc(db, 'game', 'current'));

        if (gameDoc.exists()) {
          const data = gameDoc.data();
          setGameData({
            stashLocation: data.stashLocation,
            circle: {
              center: data.circle.center,
              radius: data.circle.radius,
            },
            active: data.active,
            winnerUid: data.winnerUid,
            winTime: data.winTime?.toDate(),
          });
          setProposedRadius(data.circle.radius);

          // If there's a winner, fetch their info
          if (data.winnerUid) {
            const winnerDoc = await getDoc(doc(db, 'users', data.winnerUid));
            if (winnerDoc.exists()) {
              setWinnerInfo(winnerDoc.data());
            }
          }
        }
      } catch (error) {
        console.error('Error loading game data:', error);
      }
    };

    loadGameData();

    // Load clues
    const unsubscribe = clueService.subscribeToClues((loadedClues) => {
      setClues(loadedClues);
    });

    return () => unsubscribe();
  }, []);

  const handleSave = async () => {
    if (!isValid) {
      alert('Stash location must be within the circle!');
      return;
    }

    try {
      const currentGameData: GameData = {
        stashLocation: gameData.stashLocation,
        circle: {
          center: proposedCenter || gameData.circle.center,
          radius: proposedRadius,
        },
        active: gameData.active,
        ...(gameData.winnerUid && {
          winnerUid: gameData.winnerUid,
          winTime: gameData.winTime,
        }),
      };

      // Save game data
      await setDoc(doc(db, 'game', 'current'), currentGameData);

      // Update local state
      setGameData(prev => ({
        ...prev,
        circle: {
          center: proposedCenter || prev.circle.center,
          radius: proposedRadius,
        }
      }));
      setProposedCenter(null);

      // Create a backup with timestamp
      const timestamp = new Date().toISOString();
      await setDoc(doc(db, 'game_backups', timestamp), {
        ...currentGameData,
        backupTime: new Date(),
      });

      // Create a notification record for circle update
      await setDoc(doc(db, 'notifications', `circle_${timestamp}`), {
        type: 'circle_update',
        timestamp: new Date(),
        message: 'The search area has been updated',
        data: {
          circleCenter: proposedCenter || gameData.circle.center,
          circleRadius: proposedRadius
        }
      });

      setSnackbarMessage('Game data saved and backed up successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving game data:', error);
      setSnackbarMessage('Error saving game data');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleReset = async () => {
    if (!window.confirm('Are you sure you want to reset the game? This will clear the winner.')) {
      return;
    }

    try {
      await setDoc(doc(db, 'game', 'current'), {
        stashLocation: gameData.stashLocation,
        circle: {
          center: gameData.circle.center,
          radius: gameData.circle.radius,
        },
        active: gameData.active,
        winnerUid: null,
        winTime: null,
      });

      setGameData({
        ...gameData,
        winnerUid: undefined,
        winTime: undefined,
      });
      setWinnerInfo(null);

      setSnackbarMessage('Game reset successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error resetting game:', error);
      setSnackbarMessage('Error resetting game');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Clue management functions
  const handleAddClue = () => {
    setEditingClue(undefined);
    setClueDialogOpen(true);
  };

  const handleEditClue = (clue: Clue) => {
    setEditingClue({
      id: clue.id,
      text: clue.text,
      active: clue.active
    });
    setClueDialogOpen(true);
  };

  const handleDeleteClue = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this clue?')) {
      return;
    }

    try {
      await clueService.deleteClue(id);
      setSnackbarMessage('Clue deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting clue:', error);
      setSnackbarMessage('Error deleting clue');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSaveClue = async (clueData: { text: string; active: boolean }) => {
    try {
      if (editingClue) {
        // Update existing clue
        await clueService.updateClue(editingClue.id, clueData);
        setSnackbarMessage('Clue updated successfully');
      } else {
        // Add new clue
        const newClueId = await clueService.addClue(clueData);

        // Create a notification record for new clue
        const timestamp = new Date().toISOString();
        await setDoc(doc(db, 'notifications', `clue_${timestamp}`), {
          type: 'new_clue',
          timestamp: new Date(),
          message: 'A new clue has been added',
          data: {
            clueId: newClueId
          }
        });

        setSnackbarMessage('Clue added successfully');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving clue:', error);
      setSnackbarMessage('Error saving clue');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (!isAdmin) {
    navigate('/');
    return null;
  }

  const mapSize = 'min(calc(100vh - 400px), 100vw)';

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Stack spacing={3}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Typography variant="h5" component="h1" gutterBottom>
              Game Management
            </Typography>

          {gameData.winnerUid && winnerInfo && (
            <Paper
              elevation={2}
              sx={{
                p: 2,
                bgcolor: 'success.dark',
                border: '1px solid #00ff00',
              }}
            >
              <Typography variant="h6" gutterBottom>
                STASH FOUND!
              </Typography>
              <Typography>
                Winner: {gameData.winnerUid}
              </Typography>
              <Typography>
                Time: {gameData.winTime?.toLocaleString()}
              </Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={handleReset}
                sx={{ mt: 1 }}
              >
                Reset Game
              </Button>
            </Paper>
          )}

          <Stack direction="row" spacing={2}>
            <TextField
              label="Stash Latitude"
              type="number"
              value={gameData.stashLocation[0]}
              onChange={(e) => setGameData({
                ...gameData,
                stashLocation: [parseFloat(e.target.value), gameData.stashLocation[1]]
              })}
            />
            <TextField
              label="Stash Longitude"
              type="number"
              value={gameData.stashLocation[1]}
              onChange={(e) => setGameData({
                ...gameData,
                stashLocation: [gameData.stashLocation[0], parseFloat(e.target.value)]
              })}
            />
          </Stack>

          <TextField
            label="Circle Radius (meters)"
            type="number"
            value={proposedRadius}
            onChange={(e) => setProposedRadius(parseFloat(e.target.value))}
          />

          <FormControlLabel
            control={
              <Switch
                checked={gameData.active}
                onChange={(e) => setGameData({
                  ...gameData,
                  active: e.target.checked
                })}
              />
            }
            label="Game Active"
          />

          <Typography variant="body2" color="text.secondary">
            Click on the map to set the circle center. The orange circle shows the proposed position and radius.
          </Typography>

          <Box
            sx={{
              width: mapSize,
              height: mapSize,
              border: '2px solid #1976d2',
              borderRadius: 2,
              overflow: 'hidden',
              alignSelf: 'center'
            }}
          >
            <MapContainer
              center={gameData.circle.center}
              zoom={13}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution=""
              />
              <Circle
                center={gameData.circle.center}
                radius={gameData.circle.radius}
                pathOptions={{ color: 'red' }}
              />
              <MapClickHandler
                onCircleProposed={setProposedCenter}
                proposedCenter={proposedCenter}
                proposedRadius={proposedRadius}
              />
              <Marker position={gameData.stashLocation} />
              <CircleCheck
                stashLocation={gameData.stashLocation}
                circleCenter={proposedCenter || gameData.circle.center}
                radius={proposedRadius}
                onValidityChange={setIsValid}
              />
            </MapContainer>
          </Box>

          <Typography color={isValid ? 'success.main' : 'error.main'}>
            {isValid ? 'Stash location is within circle' : 'Warning: Stash location is outside circle!'}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!isValid}
          >
            Save Game Data
          </Button>
          <Typography variant="caption" color="text.secondary" align="center">
            Note: Game data is automatically backed up when saved
          </Typography>
        </Stack>
      </Paper>

      {/* Clues Management Section */}
      <Paper elevation={3} sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LightbulbIcon sx={{ color: '#00ff00' }} />
              <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 0 }}>
                Clues Management
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddClue}
            >
              Add Clue
            </Button>
          </Box>

          <Divider />

          {clues.length === 0 ? (
            <Typography color="text.secondary" align="center">
              No clues available. Add your first clue.
            </Typography>
          ) : (
            <List>
              {clues.map((clue) => (
                <ListItem
                  key={clue.id}
                  secondaryAction={
                    <Box>
                      <IconButton edge="end" onClick={() => handleEditClue(clue)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" onClick={() => handleDeleteClue(clue.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                  sx={{
                    mb: 1,
                    border: '1px solid',
                    borderColor: clue.active ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)',
                    borderRadius: 1,
                    backgroundColor: clue.active ? 'rgba(0, 255, 0, 0.05)' : 'rgba(255, 0, 0, 0.05)',
                  }}
                >
                  <ListItemText
                    primary={clue.text}
                    secondary={`Created: ${clue.createdAt.toLocaleString()} | Status: ${clue.active ? 'Active' : 'Inactive'}`}
                    primaryTypographyProps={{
                      sx: { fontWeight: clue.active ? 'bold' : 'normal' }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Stack>
      </Paper>

      {/* Clue Dialog */}
      <ClueDialog
        open={clueDialogOpen}
        onClose={() => setClueDialogOpen(false)}
        onSave={handleSaveClue}
        initialClue={editingClue}
        title={editingClue ? 'Edit Clue' : 'Add New Clue'}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Stack>
    </Box>
  );
};

export default Admin;