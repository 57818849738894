import { getToken } from 'firebase/messaging';
import { messaging } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Your web app's Firebase configuration
const vapidKey = 'BIFo8Vr99skhDkZxJm5A_CTxoHZX6ytbEYmjjshhyPDtGxOtcEO3l9cAHBAl3USx1cMqCax03AG24h-HwD0YMWA';

/**
 * Tests FCM setup by requesting permission, getting a token, and saving it
 * This can be called from the browser console for testing
 */
export const testFCM = async (): Promise<void> => {
  try {
    console.log('Starting FCM test...');
    
    // Check if notifications are supported
    if (!('Notification' in window)) {
      console.error('This browser does not support notifications');
      return;
    }
    
    // Check if we're in a secure context
    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(/^127(?:\\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );
    
    const isSecureContext = window.isSecureContext || isLocalhost;
    if (!isSecureContext) {
      console.error('Not in a secure context (HTTPS required)');
      return;
    }
    
    // Request notification permission
    console.log('Requesting notification permission...');
    const permission = await Notification.requestPermission();
    console.log('Notification permission:', permission);
    
    if (permission !== 'granted') {
      console.error('Notification permission not granted');
      return;
    }
    
    // Register service worker
    console.log('Registering service worker...');
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service worker registered:', registration);
    } catch (swError) {
      console.error('Service worker registration failed:', swError);
    }
    
    // Get FCM token
    console.log('Getting FCM token...');
    try {
      const token = await getToken(messaging, { vapidKey });
      
      if (!token) {
        console.error('No registration token available');
        return;
      }
      
      console.log('FCM token obtained:', token);
      
      // Save token to a test collection for verification
      await setDoc(doc(db, 'fcm_test_tokens', new Date().toISOString()), {
        token,
        timestamp: new Date(),
        userAgent: navigator.userAgent
      });
      
      console.log('Token saved to Firestore for testing');
      
      // Show a test notification
      new Notification('FCM Test Successful', {
        body: 'Your FCM setup is working correctly!',
        icon: '/logo192.png'
      });
      
    } catch (tokenError) {
      console.error('Error getting FCM token:', tokenError);
    }
    
  } catch (error) {
    console.error('FCM test failed:', error);
  }
};

// Make it available in the global scope for testing from console
(window as any).testFCM = testFCM;
