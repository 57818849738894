import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Stack, Divider, CircularProgress, Alert } from '@mui/material';
import { Lightbulb as LightbulbIcon } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Clue, clueService } from '../services/clueService';

const Clues: React.FC = () => {
  const { user, signupCompleted } = useAuth();
  const navigate = useNavigate();
  const [clues, setClues] = useState<Clue[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    if (!signupCompleted) {
      navigate('/signup');
      return;
    }

    const unsubscribe = clueService.subscribeToActiveClues((activeClues) => {
      setClues(activeClues);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user, signupCompleted, navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress sx={{ color: '#00ff00' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          background: 'linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%)',
          border: '1px solid #00ff00',
          boxShadow: '0 0 20px rgba(0, 255, 0, 0.2)',
        }}
      >
        <Stack spacing={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <LightbulbIcon sx={{ color: '#00ff00', fontSize: 40 }} />
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              sx={{
                color: '#00ff00',
                fontFamily: "'Share Tech Mono', monospace",
                letterSpacing: '0.1em',
                textShadow: '0 0 10px #00ff00',
                mb: 0,
              }}
            >
              MISSION_INTEL
            </Typography>
          </Box>

          <Typography
            variant="body1"
            sx={{
              color: '#00ff00',
              opacity: 0.8,
              fontFamily: "'Share Tech Mono', monospace",
            }}
          >
            DECRYPTED_CLUES: {clues.length}
          </Typography>

          <Divider sx={{ borderColor: 'rgba(0, 255, 0, 0.2)' }} />

          {clues.length === 0 ? (
            <Box sx={{ p: 2, border: '1px dashed rgba(0, 255, 0, 0.3)', borderRadius: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  color: '#aaa',
                  fontFamily: "'Share Tech Mono', monospace",
                  textAlign: 'center',
                }}
              >
                NO_INTEL_AVAILABLE
              </Typography>
            </Box>
          ) : (
            <Stack spacing={2}>
              {clues.map((clue) => (
                <Paper
                  key={clue.id}
                  elevation={2}
                  sx={{
                    p: 2,
                    backgroundColor: 'rgba(0, 255, 0, 0.05)',
                    border: '1px solid rgba(0, 255, 0, 0.2)',
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#fff',
                      fontFamily: "'Share Tech Mono', monospace",
                    }}
                  >
                    {clue.text}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'block',
                      mt: 1,
                      color: '#aaa',
                      fontFamily: "'Share Tech Mono', monospace",
                    }}
                  >
                    RECEIVED: {clue.createdAt.toLocaleString()}
                  </Typography>
                </Paper>
              ))}
            </Stack>
          )}
        </Stack>
      </Paper>
    </Box>
  );
};

export default Clues;
