import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, Box, Stack, Container, Typography, Fade, Alert, CircularProgress } from '@mui/material';
import { Radar as RadarIcon, Search as SearchIcon } from '@mui/icons-material';
import 'leaflet/dist/leaflet.css';
import { useAuth } from '../contexts/AuthContext';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getApp } from 'firebase/app';

// Add custom CSS to hide attribution
const hideAttribution = `
  .leaflet-control-attribution {
    display: none;
  }
`;

const ScanArea: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user, signupCompleted, signupLoading, checkSignupStatus } = useAuth();
  const lat = parseFloat(searchParams.get('lat') || '25.55');
  const lng = parseFloat(searchParams.get('lng') || '92.99');
  const position: [number, number] = [lat, lng];
  const [scanning, setScanning] = useState(false);
  const [scanComplete, setScanComplete] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [scanResult, setScanResult] = useState<{
    stashFound: boolean;
    tooLate?: boolean;
    winnerUid?: string;
  } | null>(null);

  useEffect(() => {
    // Add custom style to hide attribution
    const style = document.createElement('style');
    style.textContent = hideAttribution;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const checkUserSignup = async () => {
      if (!user) {
        navigate('/profile');
        return;
      }

      if (!signupCompleted && !signupLoading) {
        const completed = await checkSignupStatus();
        if (!completed) {
          navigate('/signup');
          return;
        }
      }

      setLoading(false);
    };

    checkUserSignup();
  }, [user, signupCompleted, signupLoading, checkSignupStatus, navigate]);

  async function handleStartScan() {
    if (!user) {
      console.log('No user found, redirecting to profile');
      navigate('/profile');
      return;
    }

    console.log('Current user state:', {
      uid: user.uid,
      email: user.email,
      isAnonymous: user.isAnonymous,
    });

    setScanning(true);
    setScanComplete(false);
    setError(null);
    setScanResult(null);

    try {
      const app = getApp();
      const functions = getFunctions(app);

      // Calculate bounds based on current view
      // This is a rough approximation for a small area around the center point
      const delta = 0.002; // About 200m in each direction
      const bounds = {
        north: lat + delta,
        south: lat - delta,
        east: lng + delta,
        west: lng - delta
      };

      console.log('Making scan request with bounds:', bounds);
      const scanAreaFunction = httpsCallable(functions, 'scanArea');
      const result = await scanAreaFunction({ bounds });
      console.log('Scan result:', result);
      const data = result.data as {
        stashFound: boolean;
        tooLate?: boolean;
        winnerUid?: string;
      };

      setScanResult(data);
    } catch (err: any) {
      console.error('Scan error:', err);
      setError(err.message || 'An error occurred while scanning');
    } finally {
      setScanning(false);
      setScanComplete(true);
    }
  }

  const mapSize = 'min(calc(100vh - 200px), calc(100vw - 32px))';

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress sx={{ color: '#00ff00' }} />
      </Container>
    );
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 4,
        px: { xs: 2, sm: 3 },
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Box sx={{
          width: mapSize,
          height: mapSize,
          position: 'relative',
          border: '2px solid #00ff00',
          borderRadius: 2,
          overflow: 'hidden',
          boxShadow: '0 0 20px rgba(0, 255, 0, 0.2)',
        }}>
          <MapContainer
            center={position}
            zoom={18}
            style={{ height: '100%', width: '100%' }}
            scrollWheelZoom={false}
            dragging={false}
            zoomControl={false}
            touchZoom={false}
            doubleClickZoom={false}
            attributionControl={false}
          >
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution=""
            />
          </MapContainer>

          {/* Scanning Overlay */}
          <Fade in={scanning}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                zIndex: 1000
              }}
            >
              <RadarIcon sx={{ fontSize: 60, color: '#00ff00', animation: 'spin 2s linear infinite' }} />
              <Typography
                variant="h6"
                sx={{
                  color: '#00ff00',
                  fontFamily: "'Share Tech Mono', monospace",
                  textShadow: '0 0 10px #00ff00',
                }}
              >
                SCANNING_TERRAIN...
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#00ff00',
                  fontFamily: "'Share Tech Mono', monospace",
                  opacity: 0.7,
                }}
              >
                ANALYZING_SATELLITE_DATA
              </Typography>
            </Box>
          </Fade>

          {/* Scan Complete Message */}
          <Fade in={scanComplete}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                zIndex: 1000
              }}
            >
              {error ? (
                <Typography
                  variant="h6"
                  sx={{
                    color: '#ff0000',
                    fontFamily: "'Share Tech Mono', monospace",
                    textAlign: 'center',
                    px: 2,
                    textShadow: '0 0 10px #ff0000',
                  }}
                >
                  SCAN_ERROR
                  <br />
                  {error.toUpperCase()}
                </Typography>
              ) : scanResult?.stashFound ? (
                <Typography
                  variant="h6"
                  sx={{
                    color: scanResult.tooLate ? '#ff8800' : '#00ff00',
                    fontFamily: "'Share Tech Mono', monospace",
                    textAlign: 'center',
                    px: 2,
                    textShadow: `0 0 10px ${scanResult.tooLate ? '#ff8800' : '#00ff00'}`,
                  }}
                >
                  {scanResult.tooLate ? (
                    <>
                      STASH_DETECTED
                      <br />
                      BUT_ALREADY_CLAIMED
                    </>
                  ) : (
                    <>
                      STASH_LOCATED
                      <br />
                      MISSION_ACCOMPLISHED
                    </>
                  )}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  sx={{
                    color: '#00ff00',
                    fontFamily: "'Share Tech Mono', monospace",
                    textAlign: 'center',
                    px: 2,
                    textShadow: '0 0 10px #00ff00',
                  }}
                >
                  SCAN_COMPLETE
                  <br />
                  NO_ASSET_SIGNATURE_DETECTED
                </Typography>
              )}
            </Box>
          </Fade>
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          mt: 2,
          width: '100%',
          maxWidth: mapSize,
        }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/?lat=${position[0]}&lng=${position[1]}&zoom=16`)}
            sx={{
              color: '#00ff00',
              borderColor: '#00ff00',
              '&:hover': {
                borderColor: '#00ff00',
                backgroundColor: 'rgba(0, 255, 0, 0.1)',
              }
            }}
          >
            CONTINUE_SURVEILLANCE
          </Button>
          <Button
            variant="contained"
            onClick={handleStartScan}
            disabled={scanning}
            sx={{
              backgroundColor: '#00ff00',
              color: '#000',
              '&:hover': {
                backgroundColor: '#00cc00',
              },
              '&:disabled': {
                backgroundColor: '#004400',
                color: '#00ff00',
              }
            }}
          >
            {scanning ? 'SCAN_IN_PROGRESS' : 'INITIATE_SCAN'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ScanArea;