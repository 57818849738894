import { useState, useEffect } from 'react';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export const useInstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    // Check if the device is iOS
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    setIsIOS(iOS);

    // Check if the app is already in standalone mode (installed)
    const standalone = window.matchMedia('(display-mode: standalone)').matches ||
                      (window.navigator as any).standalone ||
                      document.referrer.includes('android-app://');
    setIsInStandaloneMode(standalone);

    // For non-iOS devices, listen for the beforeinstallprompt event
    if (!iOS && !standalone) {
      const handler = (e: Event) => {
        e.preventDefault();
        setSupportsPWA(true);
        setPromptInstall(e as BeforeInstallPromptEvent);
      };

      window.addEventListener('beforeinstallprompt', handler);
      return () => window.removeEventListener('beforeinstallprompt', handler);
    }

    // For iOS, we'll show our own install instructions
    if (iOS && !standalone) {
      setSupportsPWA(true);
    }
  }, []);

  const handleInstallClick = async () => {
    // For iOS, we show instructions instead of triggering installation
    if (isIOS) {
      // Show iOS installation instructions
      alert('To install this app on your iOS device:\n\n1. Tap the Share button at the bottom of the screen\n2. Scroll down and tap "Add to Home Screen"\n3. Tap "Add" in the top right corner');
      return;
    }

    // For other devices, use the standard installation prompt
    if (!promptInstall) {
      return;
    }

    try {
      await promptInstall.prompt();
      const { outcome } = await promptInstall.userChoice;
      if (outcome === 'accepted') {
        setSupportsPWA(false);
      }
    } catch (error) {
      console.error('Error installing PWA:', error);
    }
  };

  return {
    supportsPWA,
    isIOS,
    isInStandaloneMode,
    handleInstallClick,
  };
};