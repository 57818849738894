import { collection, doc, setDoc, getDoc, getDocs, deleteDoc, query, orderBy, Timestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

export interface Clue {
  id: string;
  text: string;
  createdAt: Date;
  active: boolean;
}

export const clueService = {
  async addClue(clue: Omit<Clue, 'id' | 'createdAt'>): Promise<string> {
    try {
      const cluesCollection = collection(db, 'clues');
      const newClueRef = doc(cluesCollection);
      const newClue: Clue = {
        id: newClueRef.id,
        text: clue.text,
        createdAt: new Date(),
        active: clue.active
      };
      
      await setDoc(newClueRef, {
        ...newClue,
        createdAt: Timestamp.fromDate(newClue.createdAt)
      });
      
      return newClueRef.id;
    } catch (error) {
      console.error('Error adding clue:', error);
      throw error;
    }
  },

  async getClue(id: string): Promise<Clue | null> {
    try {
      const clueDoc = await getDoc(doc(db, 'clues', id));
      if (clueDoc.exists()) {
        const data = clueDoc.data();
        return {
          id: clueDoc.id,
          text: data.text,
          createdAt: data.createdAt.toDate(),
          active: data.active
        };
      }
      return null;
    } catch (error) {
      console.error('Error getting clue:', error);
      throw error;
    }
  },

  async getAllClues(): Promise<Clue[]> {
    try {
      const cluesQuery = query(collection(db, 'clues'), orderBy('createdAt', 'desc'));
      const cluesSnapshot = await getDocs(cluesQuery);
      
      return cluesSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          text: data.text,
          createdAt: data.createdAt.toDate(),
          active: data.active
        };
      });
    } catch (error) {
      console.error('Error getting all clues:', error);
      throw error;
    }
  },

  async getActiveClues(): Promise<Clue[]> {
    try {
      const cluesQuery = query(collection(db, 'clues'), orderBy('createdAt', 'desc'));
      const cluesSnapshot = await getDocs(cluesQuery);
      
      return cluesSnapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            text: data.text,
            createdAt: data.createdAt.toDate(),
            active: data.active
          };
        })
        .filter(clue => clue.active);
    } catch (error) {
      console.error('Error getting active clues:', error);
      throw error;
    }
  },

  async updateClue(id: string, updates: Partial<Omit<Clue, 'id' | 'createdAt'>>): Promise<boolean> {
    try {
      await setDoc(doc(db, 'clues', id), updates, { merge: true });
      return true;
    } catch (error) {
      console.error('Error updating clue:', error);
      return false;
    }
  },

  async deleteClue(id: string): Promise<boolean> {
    try {
      await deleteDoc(doc(db, 'clues', id));
      return true;
    } catch (error) {
      console.error('Error deleting clue:', error);
      return false;
    }
  },

  subscribeToClues(callback: (clues: Clue[]) => void): () => void {
    const cluesQuery = query(collection(db, 'clues'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(cluesQuery, (snapshot) => {
      const clues = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          text: data.text,
          createdAt: data.createdAt.toDate(),
          active: data.active
        };
      });
      
      callback(clues);
    });
    
    return unsubscribe;
  },

  subscribeToActiveClues(callback: (clues: Clue[]) => void): () => void {
    const cluesQuery = query(collection(db, 'clues'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(cluesQuery, (snapshot) => {
      const clues = snapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            text: data.text,
            createdAt: data.createdAt.toDate(),
            active: data.active
          };
        })
        .filter(clue => clue.active);
      
      callback(clues);
    });
    
    return unsubscribe;
  }
};
